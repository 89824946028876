<template>
  <b-dropdown text="Select Year" right>
    <b-dropdown-item
      v-for="year in allYears"
      :key="year.id"
      @click="$emit('change-year', year.id)"
      >{{ year.year }}</b-dropdown-item
    >
  </b-dropdown>
</template>

<script>
export default {
  props: ["allYears"],
};
</script>