<template>
  <div class="card">
    <section class="top-row-container">
      <h5>{{ sprintInfo.name }}</h5>
      <section class="icons-container">
        <p class="p-button">
          <b-icon-pencil
            @click="$emit('edit-sprint', sprintInfo)"
          ></b-icon-pencil>
        </p>
        <p class="p-button" @click="$emit('delete-sprint', sprintInfo.id)">
          <b-icon-trash></b-icon-trash>
        </p>
        <p class="p-button" @click="$emit('display-sprint', sprintInfo)">
          <b-icon-eye></b-icon-eye>
        </p>
      </section>
    </section>
    <hr />
    <p class="dates">{{ sprintInfo.startDate }} to {{ sprintInfo.endDate }}</p>
    <b-card-text>
      {{ sprintInfo.description }}
    </b-card-text>
  </div>
</template>

<script>
export default {
  props: ["sprintInfo"],
};
</script>

<style scoped>
hr {
  margin-top: 5px;
}

.card {
  padding: 20px;
}

.card:hover {
  box-shadow: 2px 2px 3px grey;
}

.p-button:hover {
  cursor: pointer;
}

.top-row-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.icons-container {
  display: flex;
  align-items: center;
}

.icons-container > * {
  margin-left: 0.5rem;
}

.dates {
  color: grey;
  font-size: 0.8rem;
}
</style>
