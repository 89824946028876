<template>
  <section>
    <h3>{{ sprintInfo.title }}</h3>
    <p>
      {{ sprintInfo.description }}
    </p>
    <hr />
    <p class="p-button" @click="$emit('edit-sprint', sprintInfo)">
      <b-icon-pencil></b-icon-pencil> Edit Sprint Info
    </p>
    <hr />
    <p class="p-button" @click="$emit('create-ticket', sprintInfo.id)">
      <b-icon-plus></b-icon-plus> Add New Ticket
    </p>
    <hr />
    <p class="p-button" @click="$emit('delete-sprint', sprintInfo.id)">
      <b-icon-trash></b-icon-trash> Delete Sprint
    </p>
    <hr />
    <p class="p-button" @click="$emit('return-to-sprints')">
      <b-icon-arrow-90deg-left></b-icon-arrow-90deg-left> Return to sprints
    </p>
  </section>
</template>

<script>
export default {
  props: ["sprintInfo"],
};
</script>

<style scoped>
.p-button:hover {
  color: white;
  cursor: pointer;
}
</style>
