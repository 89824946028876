<template>
  <section>
    <section class="service-controller">
      <!-- ICON, SERVICE & SPRINT -->
      <section class="service-toolbar-container">
        <img :src="selectedService.icon" />
        <h2>{{ selectedService.name }}</h2>
        <h2 v-if="!sprintIsDisplayed">/ {{ selectedSprint.title }}</h2>
      </section>
      <!-- SERVICE CONTROL BUTTONS -->
      <section class="service-toolbar-container">
        <b-button @click="openCreateSprintModal" v-if="sprintIsDisplayed"
          >Create Srint</b-button
        >
        <ServicesDropdown
          :servicesList="services"
          @change-service="setSelectedService"
        />
        <YearDropdown
          v-if="multipleYears"
          :allYears="allYears"
          @change-year="setSelectedYear"
        />
      </section>
    </section>
    <hr />

    <!--DISPLAY SPRINTS-->
    <section v-if="sprintIsDisplayed">
      <section
        style="width: 100%;"
        class="main-card-container"
        v-if="serviceContainsSprints"
      >
        <SprintCard
          v-for="sprint in selectedService.sprints"
          :key="sprint.id"
          :sprintInfo="sprint"
          class="sprint-card"
          @delete-sprint="openDeleteModal"
          @edit-sprint="openSprintEditor"
          @display-sprint="displaySprintTickets"
        />
      </section>
      <section v-else-if="loading" class="loading">
        <b-spinner label="Spinning" style="margin-bottom: 1rem;"></b-spinner>
        <p>Loading sprints</p>
      </section>
      <section v-else class="loading">
        <b-icon-search class="h1 mb2"></b-icon-search>
        <p>
          There are no {{ year }} sprints for this service yet, begin by adding sprints.
        </p>
      </section>
    </section>

    <!--DISPLAY TICKETS-->
    <section v-if="!sprintIsDisplayed"> 
      <section class="main-card-container">
        <TicketController
          class="control-panel"
          :sprintInfo="selectedSprint"
          @return-to-sprints="toggleSprintIsDisplayed"
          @delete-sprint="openDeleteModal"
          @edit-sprint="openSprintEditor"
          @create-ticket="openCreateTicketModal"
        />
        <Ticket
          v-for="ticket in displayTickets"
          :key="ticket.id"
          :ticketInfo="ticket"
          @edit-ticket="openEditTicketModal"
          @delete-ticket="openDeleteTicketModal"
        />
      </section>
    </section>
    
    


    <!--CREATE NEW TICKET-->
    <b-modal id="create-ticket" title="Add Ticket" hide-footer>
      <AddChangelogTicket @new-ticket="createTicket" />
    </b-modal>

    <!--EDIT TICKET-->
    <b-modal id="edit-ticket" title="Edit Ticket" hide-footer>
      <EditTicket :ticket="selectedTicket" @edit-ticket="editTicket" />
    </b-modal>

    <!--DELETE TICKET CONFIRMATION MODEL-->
    <b-modal id="delete-ticket" title="Delete Ticket" hide-footer>
      <p>
        Are you sure you want to delete this ticket?
      </p>
      <b-button variant="danger" @click="deleteTicket(selectedTicket)"
        >Yes</b-button
      >
    </b-modal>

    <!-- CREATE SPRINT MODAL -->
    <b-modal id="create-sprint" title="Create Sprint" hide-footer>
      <AddSprint @new-sprint="createSprint" />
    </b-modal>

    <!--EDIT SPRINT MODAL-->
    <b-modal id="edit-sprint" title="Edit Sprint" hide-footer>
      <EditSprint :sprintInfo="selectedSprint" @edit-sprint="editSprint" />
    </b-modal>

    <!--DELETE SPRINT CONFIRMATION MODAL-->
    <b-modal id="delete-sprint" title="Delete Sprint" hide-footer>
      <p>
        Are you sure you want to delete this sprint? All of its associated
        tickets will be deleted.
      </p>
      <b-button variant="danger" @click="deleteSprint(sprintToDelete)"
        >Yes</b-button
      >
    </b-modal>
  </section>
</template>

<script>
import ServicesDropdown from "../components/changelogs/ServicesDropdown.vue";
import AddChangelogTicket from "../components/changelogs/forms/AddChangelogTicket.vue";
import SprintCard from "../components/changelogs/SprintCard.vue";
import Ticket from "../components/changelogs/Ticket.vue";
import TicketController from "../components/changelogs/TicketController.vue";
import SprintInfo from "../repositories/ChangelogsRepository.js";
import EditSprint from "../components/changelogs/forms/EditSprint.vue";
import AddSprint from "../components/changelogs/forms/AddSprint";
import EditTicket from "../components/changelogs/forms/EditTicket.vue";
import YearDropdown from "../components/changelogs/YearDropdown.vue"; 

export default {
  components: {
    ServicesDropdown,
    YearDropdown,
    AddChangelogTicket,
    SprintCard,
    Ticket,
    TicketController,
    EditSprint,
    AddSprint,
    EditTicket,
  },
  data() {
    return {
      year: null,
      allYears: [],
      selectedService: null,
      sprintIsDisplayed: true,
      selectedSprint: null,
      selectedTicket: null,
      sprintToDelete: null, // TODO This might be a duplicate.
      userConfirmedDelete: false,
      loading: false,
      displayTickets: [],
      services: [
        {
          id: 0,
          name: "Website",
          icon: require("@/assets/website.png"),
          JSONdata: null,
          sprints: [],
        },
        {
          id: 1,
          name: "Dashboard",
          icon: require("@/assets/dashboard.png"),
          JSONdata: null,
          sprints: [],
        },
        {
          id: 2,
          name: "Organizations",
          icon: require("@/assets/organization.png"),
          JSONdata: null,
          sprints: [],
        },
        {
          id: 3,
          name: "Operator",
          icon: require("@/assets/operator.png"),
          JSONdata: null,
          sprints: [],
        },
        {
          id: 4,
          name: "Delivery",
          icon: require("@/assets/delivery.png"),
          JSONdata: null,
          sprints: [],
        },
        {
          id: 5,
          name: "Commuter",
          icon: require("@/assets/commuter.png"),
          JSONdata: null,
          sprints: [],
        },
        {
          id: 6,
          name: "API",
          icon: require("@/assets/postman.png"),
          JSONdata: null,
          sprints: [],
        },
      ],
    };
  },
  computed: {
    serviceContainsSprints() {
      return this.selectedService.sprints.length > 0;
    },
    multipleYears() {
      return this.allYears.length > 1;
    },
  },
  methods: {
    /**
    Whenever a service is toggled on the page, this
    method will set the service that was selected
    as the selectedService.
    @param {int} serviceID: the id of the selected service.
    */
    setSelectedService(serviceID) {
      this.selectedService = this.services[serviceID];
      this.resetServices();
    },
    /**
    Whenever the year is toggled on the page, this
    method will set the current year.
    @param {int} yearID: the id of the selected year.
    */
    setSelectedYear(yearID) {
      this.year = this.allYears[yearID].year;
      this.selectedService.resetServices();
    },
    /**
    Gets all sprints for the selected service.
    @param {string} service: the name of the service.
    @param {int} year: the year in which the sprint took place.
    */
    async getSprintInfo(service, year) {
      // Get sprint info from the Lula-API
      this.loading = true;
      const sprints = await SprintInfo.getSprintsByService(service, year);
      this.loading = false;

      // Add each sprint object to the currently selected service.
      sprints.data.forEach((sprint) => {
        this.selectedService.sprints.push({
          title: sprint.title,
          id: sprint.changelog_id,
          name: sprint.title,
          description: sprint.description,
          startDate: sprint.start_date,
          endDate: sprint.end_date,
        });
      });
    },
    /**
    Opens the modal for editing sprints.
    @param {json} sprintInfo: the selected sprint information.
    */
    openSprintEditor(sprintInfo) {
      this.selectedSprint = sprintInfo;
      this.$bvModal.show("edit-sprint");
    },
    /**
    UPDATE a sprint on the lula-API
    @param (int) sprintID: The ID of the sprint that has to be updated.
    @param (json) formInfo: The json date from the form input.
    */
    async editSprint(sprintID, formInfo) {
      formInfo.year = this.year;
      formInfo.service = this.selectedService.name;
      formInfo.id = sprintID;
      await SprintInfo.updateSprint(formInfo);
      this.resetServices();
      this.$bvModal.hide("edit-sprint");
    },
    openCreateSprintModal() {
      this.$bvModal.show("create-sprint");
    },
    /**
    CREATE a new sprint on the Lula-API
    @param {string} title: the sprint title
    @param {string} description: the sprint description
    @param {date} startDate: the sprint start date
    @param {date} endDate: the sprint end date
    @param {boolean} createForAll: create for all services
    */
    async createSprint(formData, createForAll) {
      if (createForAll) {
        this.services.forEach(async (service) => {
          await SprintInfo.createSprint({
            title: formData.title,
            year: this.year,
            service: service.name,
            start_date: formData.startDate,
            end_date: formData.endDate,
            description: formData.description,
          });
        });
      } else {
        await SprintInfo.createSprint({
          title: formData.title,
          year: this.year,
          service: this.selectedService.name,
          start_date: formData.startDate,
          end_date: formData.endDate,
          description: formData.description,
        });
      }

      // Reset the list of sprints to include the newly created one.
      this.resetServices();
      this.$bvModal.hide("create-sprint");
    },
    openDeleteModal(id) {
      this.sprintToDelete = id;
      this.$bvModal.show("delete-sprint");
    },
    confirmDelete() {
      this.deleteSprint(this.sprintToDelete);
    },
    /**
    DELETE a sprint from the Lula-API. Once a sprint is deleted
    the page is reset and the modal is hidden. If a sprint is
    being viewed and deleted, it will return to the services
    vue.
    @param {id} sprintID: the id of the sprint that must be deleted.
    */
    async deleteSprint(sprintID) {
      await SprintInfo.deleteSprint(sprintID);
      this.resetServices();
      this.$bvModal.hide("delete-sprint");

      if (!this.sprintIsDisplayed) {
        this.sprintIsDisplayed = true;
      }
    },
    /**
    GETs tickets from the Lula-API based on the sprint id
    of the currently selected sprint.
    @param {json} sprintData: The selected sprint.
    */
    async displaySprintTickets(sprintData) {
      // Set the selected sprint.
      this.selectedSprint = sprintData;

      // Get tickets associated with the selected sprint.
      const tickets = await SprintInfo.getTicketsBySprint(sprintData.id);
      

      // Clear the container of tickets and then repopulate it.
      this.displayTickets = [];
      tickets.data.forEach((ticket) => {
        this.displayTickets.push(ticket);
      });  
      // Display the sprint with its associated tickets.
      this.sprintIsDisplayed = false;
    },
    openCreateTicketModal() {
      this.$bvModal.show("create-ticket");
    },
    /**
    CREATE a new ticket on the Lula-API. We refresh the sprint
    tickets and close the modal on creation of the ticket.
    @param {json} ticketData: the ticket data from the create ticket form.
    */
    async createTicket(ticketData) {
      ticketData.changelog_id = this.selectedSprint.id;
      await SprintInfo.createTicket(ticketData);
      this.displaySprintTickets(this.selectedSprint);
      this.$bvModal.hide("create-ticket");
    },
    openEditTicketModal(ticketData) {
      this.selectedTicket = ticketData;
      this.$bvModal.show("edit-ticket");
    },
    /**
    UPDATE a ticket on the Lula-API. We refresh the sprint
    tickets and close the modal on updating the ticket.
    @param {int} ticketID: the id of the ticket to be updated.
    @param {json} ticketData: the ticket data from the create ticket form.
    */
    async editTicket(ticketID, ticketData) {
      ticketData.changelog_id = this.selectedSprint.id;
      ticketData.id = ticketID;
      await SprintInfo.updateTicket(ticketData);
      this.displaySprintTickets(this.selectedSprint);
      this.$bvModal.hide("edit-ticket");
    },
    openDeleteTicketModal(ticketID) {
      this.selectedTicket = ticketID;
      this.$bvModal.show("delete-ticket");
    },
    /**
    DELETE a ticket on the Lula-API. We refresh the sprint
    tickets and close the modal on deleting the ticket.
    @param {int} ticketID: the id of the ticket to be deleted.
    */
    async deleteTicket(ticketID) {
      await SprintInfo.deleteTicket(ticketID);
      this.displaySprintTickets(this.selectedSprint);
      this.$bvModal.hide("delete-ticket");
    },
    toggleSprintIsDisplayed() {
      this.sprintIsDisplayed = !this.sprintIsDisplayed;
    },
    resetServices() {
      this.sprintIsDisplayed = true;
      this.selectedService.sprints = [];
      this.getSprintInfo(this.selectedService.name, this.year);
    },
    /**
    Set the current year as well as all the year for which
    there are changelogs.
    */
    setYears() {
      const date = new Date();
      let thisYear = date.getFullYear();
      this.year = thisYear;

      while (thisYear >= 2021) {
        this.allYears.push({
          id: this.allYears.length,
          year:thisYear
        });
        thisYear--;
      }
    }
  },

  created() {
    this.selectedService = this.services[0];
    this.getSprintInfo(this.selectedService.name, 2021);
    this.setYears();
  },
};
</script>

<style scoped>
h2 {
  font-size: 2rem;
  font-family: Nunito;
  font-weight: bolder;
  display: inline-block;
  margin-top: 8px;
}

select {
  background-color: transparent;
}

img {
  width: 46px;
  height: 46px;
}

.service-controller {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1rem 0 1rem 0;
}

.service-toolbar-container {
  max-height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
}

.service-toolbar-container > * {
  margin-left: 0.4rem;
}

.main-card-container {
  margin-top: 20px;
  width: 100%;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-gap: 0.65em;
}

.control-panel {
  grid-column: 1/2;
  grid-row: 1/3;
  background-color: #757575;
  border-radius: 1rem;
  padding: 25px;
}

.loading {
  grid-column: 2/3;
  grid-row: 2/3;
  padding-top: 3rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.empty-message {
  grid-column: 2/4;
  grid-row: 1;
}
</style>
