<template>
  <b-form @submit.prevent="createNewTicket">
    <b-form-group
      id="input-group-1"
      label="Ticket Number:"
      label-for="input-1"
      placeholder="Enter the azure ticket number."
    >
      <b-form-input
        id="input-1"
        v-model="ticketData.azure_ticket_number"
        type="text"
        placeholder="Enter the azure ticket number."
        required
      ></b-form-input>
    </b-form-group>

    <b-form-group
      id="input-group-1"
      label="Ticket Title:"
      label-for="input-1"
      placeholder="Enter the azure ticket title."
    >
      <b-form-input
        id="input-1"
        v-model="ticketData.title"
        type="text"
        placeholder="Enter the azure ticket title."
        required
      ></b-form-input>
    </b-form-group>

    <b-form-group id="input-group-2" label="Ticket Type:" label-for="input-2">
      <b-form-input
        id="input-2"
        v-model="ticketData.description"
        type="text"
        placeholder="Enter the type of ticket."
        required
      ></b-form-input>
    </b-form-group>

    <b-form-group id="input-group-3" label="Release Date:" label-for="input-3">
      <b-form-datepicker
        id="example-datepicker"
        v-model="ticketData.release_date"
        class="mb-2"
      ></b-form-datepicker>
    </b-form-group>

    <b-button type="submit" style="margin-right: 0.4rem;">Submit</b-button>
    <b-button type="reset">Reset</b-button>
  </b-form>
</template>

<script>
export default {
  data() {
    return {
      ticketData: {
        ticket_id: null,
        azure_ticket_number: null,
        title: null,
        description: null,
        release_date: null,
      },
    };
  },
  methods: {
    createNewTicket() {
      this.$emit("new-ticket", this.ticketData);
    },
  },
};
</script>
