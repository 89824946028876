<template>
  <b-form @submit.prevent="createChangelog">
    <!--This is the title input-->
    <b-form-group
      id="input-group-1"
      label="Sprint Title:"
      label-for="input-1"
      placeholder="Enter the sprint title"
    >
      <b-form-input
        id="input-1"
        v-model="sprint.title"
        type="text"
        placeholder="Enter the sprint title."
        required
      ></b-form-input>
    </b-form-group>

    <!--This is the description input-->
    <b-form-group
      id="input-group-1"
      label="Sprint Description:"
      label-for="input-2"
      placeholder="Enter the sprint description."
    >
      <b-form-input
        id="input-2"
        v-model="sprint.description"
        type="text"
        placeholder="Enter the sprint description."
        required
      ></b-form-input>
    </b-form-group>

    <!--Calendar selection for start date-->
    <b-form-group id="input-group-2" label="Start Date:" label-for="input-3">
      <b-form-datepicker
        id="start-datepicker"
        v-model="sprint.startDate"
        class="mb-2"
      ></b-form-datepicker>
    </b-form-group>

    <!--Calendar selection for end date-->
    <b-form-group id="input-group-2" label="End Date:" label-for="input-3">
      <b-form-datepicker
        id="end-datepicker"
        v-model="sprint.endDate"
        class="mb-2"
      ></b-form-datepicker>
    </b-form-group>

    <!--Checkbox to create sprint for all services.-->
    <b-form-checkbox
      id="checkbox-1"
      v-model="createSprintForAllServices"
      name="create-for-all-services"
      value="true"
      unchecked-value="false"
    >
      Create this sprint for all services
    </b-form-checkbox>

    <b-button type="submit" style="margin-right: 0.4rem;"
      >Create Sprint</b-button
    >
    <b-button type="reset">Add Another Sprint</b-button>
  </b-form>
</template>

<script>
export default {
  data() {
    return {
      sprint: {
        title: null,
        description: null,
        startDate: null,
        endDate: null,
      },
      createSprintForAllServices: null,
    };
  },
  computed: {
    checkBoxValue() {
      return this.createSprintForAllServices === "true";
    },
  },
  methods: {
    createChangelog() {
      this.$emit("new-sprint", this.sprint, this.checkBoxValue);
    },
    toggleCreateSprintForAllServices() {
      this.createForAllServices = true;
    },
  },
};
</script>
