import axios from "axios";
import errorHandler from "./Repository";

const changelogsURLPrefix = "/dashboard/changelog/";
const ticketsURLPrefix = "/dashboard/tickets/";

export default {
  /**
  GET sprints, filtering by service and year.
  @param {string} service: the service associated with the sprint.
  @param {int} year: the year in which the sprint took place.
  @return {json} All sprints for the service for the given year.     
  */
  getSprintsByService(service = null, year = null) {
    return axios
      .get(`${changelogsURLPrefix}?year=${year}&service=${service}`)
      .then(({ data }) => {
        return data;
      })
      .catch(errorHandler);
  },

  /**
   CREATE a sprint on the Lula-API
   @param {json} sprintInfo: The json data captured from the update form.
  */
  createSprint(sprintInfo) {
    return axios
      .post(`${changelogsURLPrefix}`, sprintInfo)
      .then(({ data }) => {
        return data;
      })
      .catch(errorHandler);
  },

  /**
   UPDATE a sprint on the Lula-API
   @param {int} sprintID: The id of the sprint that needs to be updated.
   @param {json} sprintInfo: The json data captured from the update form.
  */
  updateSprint(sprintInfo) {
    return axios
      .put(`${changelogsURLPrefix}`, sprintInfo)
      .then(({ data }) => {
        return data;
      })
      .catch(errorHandler);
  },

  /**
   DELETE a sprint from the Lula-API
   @param {int} sprintID: the id of the sprint to be deleted.
  */
  deleteSprint(sprintID) {
    return axios
      .delete(`${changelogsURLPrefix}?id=${sprintID}`)
      .then(({ data }) => {
        return data;
      })
      .catch(errorHandler);
  },

  /**
   GET all tickets
   @return {json} a json object containing all the tickets on the Lula-API
  */
  getAllTickets() {
    return axios
      .get(`${ticketsURLPrefix}` + "/tickets")
      .then(({ data }) => {
        return data;
      })
      .catch(errorHandler);
  },

  /**
   GET all tickets filtered by service.
   @param {int} sprintID: the ID of the sprint for which the tickets were completed.
   @return {json} the tickets for the sprint.
  */
  getTicketsBySprint(sprintID) {
    return axios
      .get(`${ticketsURLPrefix}?changelog_id=${sprintID}`)
      .then(({ data }) => {
        return data;
      })
      .catch(errorHandler);
  },
  /**
  CREATE a ticket on the Lula-API
  @param {json} ticketInfo: The json data captured from the create ticket form.
 */
  createTicket(ticketInfo) {
    return axios
      .post(`${ticketsURLPrefix}`, ticketInfo)
      .then(({ data }) => {
        return data;
      })
      .catch(errorHandler);
  },
  /**
  UPDATE a sprint on the Lula-API
  @param {int} sprintID: The id of the sprint that needs to be updated.
  @param {json} sprintInfo: The json data captured from the update form.
 */
  updateTicket(ticketInfo) {
    return axios
      .put(`${ticketsURLPrefix}`, ticketInfo)
      .then(({ data }) => {
        return data;
      })
      .catch(errorHandler);
  },

  /**
  DELETE a sprint from the Lula-API
  @param {int} sprintID: the id of the sprint to be deleted.
 */
  deleteTicket(ticketID) {
    return axios
      .delete(`${ticketsURLPrefix}?ticket_id=${ticketID}`)
      .then(({ data }) => {
        return data;
      })
      .catch(errorHandler);
  },
};
