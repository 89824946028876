<template>
  <b-dropdown text="Select Service" right>
    <b-dropdown-item
      v-for="service in servicesList"
      :key="service.id"
      @click="$emit('change-service', service.id)"
      >{{ service.name }}</b-dropdown-item
    >
  </b-dropdown>
</template>

<script>
export default {
  props: ["servicesList"],
};
</script>
