<template>
  <b-card>
    <section class="top-row-container">
      <p>{{ ticketInfo.title }}</p>
      <section class="icons-container">
        <p class="p-button" @click="$emit('edit-ticket', ticketInfo)">
          <b-icon-pencil></b-icon-pencil>
        </p>
        <p
          class="p-button"
          @click="$emit('delete-ticket', ticketInfo.ticket_id)"
        >
          <b-icon-trash></b-icon-trash>
        </p>
      </section>
    </section>
    <p>{{ ticketInfo.azure_ticket_number }}</p>
    <b-card-text>
      {{ ticketInfo.description }}
    </b-card-text>
    <b-card-text>
      {{ ticketInfo.release_date }}
    </b-card-text>
  </b-card>
</template>

<script>
export default {
  props: ["ticketInfo"],
};
</script>

<style scoped>
.top-row-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.icons-container {
  display: flex;
  align-items: center;
}

.icons-container > * {
  margin-left: 0.5rem;
}

.p-button:hover {
  cursor: pointer;
}
</style>
