<template>
  <b-form @submit.prevent="createChangelog">
    <!--This is the title input-->
    <b-form-group id="input-group-1" label="Sprint Title:" label-for="input-1">
      <b-form-input
        id="input-1"
        v-model="sprint.title"
        type="text"
        :placeholder="sprintInfo.title"
        required
      ></b-form-input>
    </b-form-group>

    <!--This is the description input-->
    <b-form-group
      id="input-group-1"
      label="Sprint Description:"
      label-for="input-2"
    >
      <b-form-input
        id="input-2"
        v-model="sprint.description"
        type="text"
        :placeholder="sprintInfo.description"
        required
      ></b-form-input>
    </b-form-group>

    <!--Calendar selection for start date-->
    <b-form-group id="input-group-2" label="Start Date:" label-for="input-3">
      <b-form-datepicker
        id="start-datepicker"
        v-model="sprint.start_date"
        class="mb-2"
      ></b-form-datepicker>
    </b-form-group>

    <!--Calendar selection for end date-->
    <b-form-group id="input-group-2" label="End Date:" label-for="input-3">
      <b-form-datepicker
        id="end-datepicker"
        v-model="sprint.end_date"
        class="mb-2"
      ></b-form-datepicker>
    </b-form-group>

    <b-button type="submit" style="margin-right: 0.4rem;">
      Update
    </b-button>
  </b-form>
</template>

<script>
export default {
  data() {
    return {
      sprint: {
        title: null,
        description: null,
        start_date: null,
        end_date: null,
      },
      createSprintForAllServices: null,
    };
  },
  props: ["sprintInfo"],
  methods: {
    createChangelog() {
      this.$emit("edit-sprint", this.sprintInfo.id, this.sprint);
    },
  },
};
</script>
